<template>
  <el-container>
    <el-main style="overflow: auto !important">
      <pro-document-breadcrumbs
        :breadcrumbs="breadcrumbs"
        :user-role="$route.params.userRole"
        :tender-id="$route.params.id"
        :edit-user-route-id="editUserRouteId"
        @pro-load-dashboard="loadDashboard"
      ></pro-document-breadcrumbs>
      <pro-document-progress-bar
        :trees="progressBar"
        class="q-mt-md q-mx-sm"
      ></pro-document-progress-bar>
      <router-view
        @pro-form-submit="onProFormSubmit"
        ref="view"
        :document-info="documentInfo"
        :document-status="documentStatus"
      ></router-view>
    </el-main>
  </el-container>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProDocumentProgressBar from "@/components/PROSmart/ProDocumentProgressBar";

export default {
  components: { ProDocumentProgressBar, ProDocumentBreadcrumbs },
  data() {
    return {
      processPath: window.location.hash,
      breadcrumbs: [],
      progressBar: [],
      editUserRouteId: null,
      dashboardKey: "",
      documentInfo: {},
      documentStatus: -1,
    };
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  beforeRouteUpdate(to, from, next) {
    this.updateBreadcrumbs(to);
    this.loadDashboard(to);
    next();
  },
  methods: {
    updateBreadcrumbs(route) {
      /** @type {[{name: string, icon?: string, to?: {name: string}}]} */
      let document = [
        {
          name: "Document " + (this.documentInfo.ref || ""),
          icon: "PROSmart-DocumentBox",
        },
      ];

      if (route.name !== "DocumentDashboard") {
        document[0].to = { name: "DocumentDashboard" };
        document.push({ name: route.meta.title });
      }
      this.breadcrumbs = document;
    },
    loadStepper() {
      return this.$proSmart.tender
        .getStepper(
          this,
          this.$route.params.id,
          this.$route.params.userRole,
          this.$route.params.category,
          this.$route.params.requestType
        )
        .then((stepperGroup) => {
          this.progressBar = stepperGroup.stepperList;
          sessionStorage.setItem(
            "stepperList",
            JSON.stringify(stepperGroup.stepperList)
          );

          this.dashboardKey = stepperGroup.dashboardKey;
          // this.loadDashboard(this.$route);

          let editUser = stepperGroup.stepperList.find(
            (b) => b.descriptionRkey === "CustMenu.Com.Stepper.AssignedUser"
          );

          if (editUser) {
            this.editUserRouteId = editUser.id;
          }

          return stepperGroup.stepperList;
        })
        .catch(() => {
          sessionStorage.removeItem("stepperList");
          return Promise.reject();
        });
    },
    loadDashboard(route) {
      if (route.name === "DocumentDashboard") {
        this.$nextTick(() => {
          if (this.$refs.view.loadStepperCompleted) {
            this.$refs.view.loadStepperCompleted(this.dashboardKey);
          }
        });
      }
    },
    loadDocumentInfo() {
      return this.$proSmart.documentUi
        .getInfo(this, this.$route.params.id)
        .then((documentInfo) => {
          this.documentInfo = documentInfo;
        });
    },
    loadDocumentStatus() {
      return this.$proSmart.tender
        .getTenderStatus(this, this.$route.params.id)
        .then(({ statusCode }) => {
          this.documentStatus = statusCode;
        });
    },
    loadActionMenu(stepperList) {
      const stepperIds = stepperList
        .flatMap((stepper) => [stepper, ...stepper.childrenStepperCode])
        .map((stepper) => stepper.id);

      return this.$proSmart.tender
        .getAllActionMenu(this, this.$route.params.id, stepperIds)
        .then((actionMenuList) => {
          sessionStorage.setItem(
            "actionMenuList",
            JSON.stringify(actionMenuList)
          );

          return actionMenuList;
        })
        .catch(() => {
          sessionStorage.removeItem("actionMenuList");
          return Promise.reject();
        });
    },
    onProFormSubmit(workflowCode) {
      const todoItem = JSON.parse(sessionStorage.getItem("todoItem"));
      const params = this.$route.params;

      if (
        workflowCode &&
        todoItem &&
        todoItem.targetWorkflowCode &&
        parseInt(params.id) === todoItem.tenderId &&
        params.category === todoItem.category &&
        params.requestType === todoItem.requestType &&
        workflowCode === todoItem.targetWorkflowCode
      ) {
        sessionStorage.removeItem("todoItem");
        this.$router.push({ name: "TodoList" }); //redirect to to-do list on target workflow submit
      } else {
        this.loadStepper()
          .then((stepperList) => {
            let route = { name: "DocumentDashboard" };

            if (params.stepperId) {
              const stepper = stepperList
                .flatMap((stepper) => [stepper, ...stepper.childrenStepperCode])
                .find((stepper) => stepper.id === parseInt(params.stepperId));
              if (stepper && stepper.viewRoute) {
                route = {
                  name: stepper.viewRoute,
                  params: {
                    mode: "View",
                    code: stepper.viewFormCode,
                    stepperId: stepper.id,
                  },
                };
              }
            }

            this.loadActionMenu(stepperList).then(() =>
              this.$router.push(route)
            );
          })
          .catch(() => {});
        this.loadDocumentInfo();
        this.loadDocumentStatus();
      }
    },
  },
  mounted() {
    // this.invokeService(
    //   "PROSmartCodeTable",
    //   "GetRouteWorkflowCode",
    //   ["DocumentViewForm", "TenderNotice"],
    //   function (msg) {
    //     console.log(msg.ReturnData.$);
    //   },
    //   function (msg) {
    //     console.log(msg);
    //   }
    // );

    // this.invokeService("PCSystem","GetUserAccountInfo",["0000110164"], (b)=>{
    //   console.log(b);
    // })

    this.loadStepper()
      .then((stepperList) => this.loadActionMenu(stepperList))
      .then(() => this.loadDashboard(this.$route));
    this.loadDocumentInfo().then(() => this.updateBreadcrumbs(this.$route));
    this.loadDocumentStatus();
  },
};
</script>
