<template>
  <div>
    <div class="row q-col-gutter-md">
      <div
        :class="`${lists.length >= 6 ? 'col-2' : 'col'}`"
        v-for="list in lists"
        :key="list.id"
      >
        <q-linear-progress class="q-mb-xs" :value="1" :color="list.color" />

        <div class="row q-col-gutter-md">
          <div class="col">
            <router-link
              :to="list.action.route || ''"
              @click.native="showErrorMessages(list.action.messages)"
              :tag="list.action.route || list.action.messages ? 'a' : 'div'"
            >
              <div
                :class="[
                  `text-${list.color}`,
                  'text-h5',
                  'text-weight-bold',
                  'text-center',
                  'q-mt-xs',
                  'q-mb-sm',
                ]"
              >
                {{ list.label }}
              </div>
            </router-link>

            <router-link
              v-for="item in list.children"
              :key="item.id"
              :to="item.action.route || ''"
              @click.native="showErrorMessages(item.action.messages)"
              :tag="item.action.route || item.action.messages ? 'a' : 'div'"
            >
              <div :class="`text-${item.color} text-center`">
                {{ item.label }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProStepperMixin from "@/views/mixins/ProStepperMixin";

export default {
  name: "ProDocumentProgressBar",
  mixins: [ProStepperMixin],
  props: {
    trees: Array,
  },
  methods: {
    getColor(stepperId) {
      if (this.$route.params.stepperId === stepperId) {
        return "completed";
      } else {
        return "grey";
      }
    },
    getAction(node) {
      const viewAction = this.getViewAction(node);
      const editAction = this.getEditAction(node);

      if (viewAction && editAction) {
        if (this.isCompleted(node)) {
          return viewAction;
        } else {
          return editAction;
        }
      } else {
        return viewAction || editAction || {};
      }
    },
    getViewAction(node) {
      if (node.viewRoute) {
        return {
          route: {
            name: node.viewRoute,
            params: {
              mode: "View",
              code: node.viewFormCode,
              stepperId: node.id,
            },
          },
        };
      } else if (node.viewMessages) {
        return {
          messages: node.viewMessages,
        };
      } else {
        return null;
      }
    },
    getEditAction(node) {
      if (node.editRoute) {
        return {
          route: {
            name: node.editRoute,
            params: {
              mode: "Edit",
              code: node.editWorkflowProcessCode,
              stepperId: node.id,
            },
          },
        };
      } else if (node.editMessages) {
        return {
          messages: node.editMessages,
        };
      } else {
        return null;
      }
    },
    isCompleted(node) {
      return (
        node.editWorkflowProcessCode == null || node.flowHistory.length > 0
      );
    },
  },
  computed: {
    lists() {
      //let inactive = false;

      return this.trees
        .filter((parentNode) => parentNode.required)
        .map((parentNode) => {
          // const childrenCompleted = parentNode.childrenStepperCode.every(
          //   (childNode) => this.isCompleted(childNode)
          // );

          const resultNode = {
            id: parentNode.id,
            label: this.getRes(parentNode.descriptionRkey),
            color: this.getColor(parentNode.id),
            action: this.getAction(parentNode),
            children: parentNode.childrenStepperCode
              .filter((childNode) => childNode.required)
              .map((childNode) => ({
                id: childNode.id,
                label: this.getRes(childNode.descriptionRkey),
                color: this.getColor(childNode.id),
                action: this.getAction(childNode),
              })),
          };

          // if (!inactive && !allCompleted) {
          //   //this tree in-progress, all subsequent trees will be inactive
          //   inactive = true;
          // }

          return resultNode;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  .text-required {
    font-weight: bold;
  }
}
</style>
